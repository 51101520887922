<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">기준일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="bsnDateRange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <report-view ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">회원권 변동 현황</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="onPrintButtonClicked"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="grid"
                v-bind="gridOptions"
                :data-source="content.membershipChangeStatus"
                :aggregates="membershipChangeGridAggregates"
                @actionComplete="membershipChangesStatusGridActionComplete"
                @queryCellInfo="onBodyGridQueryCellInfo"

              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
// import InputText from "@/components/common/text/InputText";
// import CustomShortcutButton from "@/components/button/CustomShortcutButton";
import ErpButton from "@/components/button/ErpButton.vue";
// import {SHORTCUT_KEYS} from "@/utils/KeyboardUtil";
import {Aggregate, ExcelExport, Filter, ForeignKey, Group, Page, Resize} from "@syncfusion/ej2-vue-grids";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {numberWithCommas} from "@/utils/number";
import grid from "@/components/grid/Grid.vue";
import ReportView from "@/components/common/report/ReportView";

// import GolfErpAPI from "@/api/v2/GolfErpAPI";
// import { orderBy as _orderBy, maxBy as _maxBy, minBy as _minBy, groupBy as _groupBy, } from "lodash";

export default {
  name: "MembershipChangeStatus",
  components: {
    InputDateRange,
    EjsGridWrapper,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      searchOptions: {
        bsnDateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        bsnDateTo: moment().format('YYYY-MM-DD'),
      },
      content: {
        membershipChangeStatus: [],
      },
    };
  },
  created() {},
  computed: {
    grid() {
      return grid;
    },
    isPopupOpened() {
      return false;
    },
    bsnDateRange: {
      get() {
        return {
          from: this.searchOptions.bsnDateFrom,
          to: this.searchOptions.bsnDateTo,
        };
      },
      set(dateRange) {
        this.searchOptions.bsnDateFrom = dateRange.from;
        this.searchOptions.bsnDateTo = dateRange.to;
      }
    },
    gridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
          Aggregate,
          Group,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        allowResizing:true,
        allowGrouping: false,
        isSelectedRowRetain:true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        // groupSettings: {
        //   columns: ["newCnt","transGiveCnt","transTakeCnt", "inherCnt","giftCnt", "outCnt", "userCnt","nameCnt"],
        //   showDropArea: false,
        // },
        columns: [

          {
            field: 'renameDate',
            headerText: '년월',
            textAlign: 'center',
            type: 'string',
            height: 30,
            width: 90,
            rowSpan: 2,
          },
          {
            field: 'cprDiv',
            headerText: '구분',
            textAlign: 'center',
            height: 30,
            width: 150,
            type: 'string',
          },
          {
            field: 'newCnt',
            headerText: '신규',
            textAlign: 'right',
            height: 30,
            width: 150,
            type: 'string',
            valueAccessor: (field,data) => {
              return data[field] === "0" ? "-" : numberWithCommas(data[field]);
            },
          },
          {
            field: 'transGiveCnt',
            headerText: '양도(명의변경)',
            textAlign: 'right',
            height: 30,
            width: 150,
            type: 'string',
            valueAccessor: (field,data) => {
              return data[field] === "0" ? "-" : numberWithCommas(data[field]);
            },
          },
          {
            field: 'transTakeCnt',
            headerText: '양수(명의변경)',
            textAlign: 'right',
            height: 30,
            width: 150,
            type: 'string',
            valueAccessor: (field,data) => {
              return data[field] === "0" ? "-" : numberWithCommas(data[field]);
            },
          },
          {
            field: 'inherCnt',
            headerText: '상속',
            textAlign: 'right',
            height: 30,
            width: 150,
            type: 'string',
            valueAccessor: (field,data) => {
              return data[field] === "0" ? "-" : numberWithCommas(data[field]);
            },
          },
          {
            field: 'giftCnt',
            headerText: '증여',
            textAlign: 'right',
            height: 30,
            width: 150,
            type: 'string',
            valueAccessor: (field,data) => {
              return data[field] === "0" ? "-" : numberWithCommas(data[field]);
            },
          },
          {
            field: 'outCnt',
            headerText: '말소(탈회)',
            textAlign: 'right',
            height: 30,
            width: 150,
            type: 'string',
            valueAccessor: (field,data) => {
              return data[field] === "0" ? "-" : numberWithCommas(data[field]);
            },
          },
          {
            headerText: "명의변경(미신고)",
            columns: [
              {
                field: "userCnt",
                headerText: "이용자변경",
                type: "number",
                isNumericType: true,
                inputNumberProperty: {maxLength: 4},
                multiEdit: {
                  min: 1,
                  max: 9999,
                  propMaxLength: 4,
                  allowDot: false,
                  allowMinus: false,
                  displayComma: false,
                },
                minWidth: 16,
                width: 150,
                textAlign: "right",
                allowEditing: true,
                valueAccessor: (field,data) => {
                  return data[field] === "0" ? "-" : numberWithCommas(data[field]);
                },
              },
              {
                field: "nameCnt",
                headerText: "상호변경",
                type: "number",
                isNumericType: true,
                inputNumberProperty: {maxLength: 4},
                multiEdit: {
                  min: 1,
                  max: 9999,
                  propMaxLength: 4,
                  allowDot: false,
                  allowMinus: false,
                  displayComma: false,
                },
                minWidth: 16,
                width: 150,
                textAlign: "right",
                allowEditing: true,
                valueAccessor: (field,data) => {
                  return data[field] === "0" ? "-" : numberWithCommas(data[field]);
                },
              },
            ]
          }
        ],
      };
    },
    membershipChangeGridAggregates() {
      return [
        {
          columns: [
            {
              field: "cprDiv",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "newCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "transGiveCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "transTakeCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "inherCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "giftCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "outCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "userCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "nameCnt",
              aggregationType: "TotalSum",
            },
          ],
        },
      ];
    },
  },
  mounted() {},
    methods: {
      onViewButtonClicked() {
        this.fetchMembershipChangeStatus();
      },
      async fetchMembershipChangeStatus() {
        let params = {
          fromDate: this.searchOptions.bsnDateFrom,
          toDate: this.searchOptions.bsnDateTo
        };
        if (params.fromDate == undefined || params.toDate == undefined) {
          this.errorToast("기준일자를 입력해주세요");
        } else {
          let res = await GolfErpAPI.getMembershipChangeStatus(params);
          let bsnDateBefore = null;
          if (res) {
            res = res.map((data) => {
              const isTopLine = bsnDateBefore !== data.renameDate;
              bsnDateBefore = JSON.parse(JSON.stringify(data.renameDate));
              return {
                ...data,
                isTopLine: isTopLine,
              };

            });
            this.content.membershipChangeStatus = res;
          }
        }
      },

      onPrintButtonClicked() {
        if (this.content.membershipChangeStatus.length === 0) {
          this.errorToast("조회된 데이터가 없습니다");
        } else {
          const result = this.$refs.grid.getGridBatchData();

          if (result.length < 1) {
            return this.errorToast(this.$t('report.popupMessage.noData'));
          }

          const searchOptionsList = [
            {
              key: '조회일자',
              value: `${this.searchOptions.bsnDateFrom} ~ ${this.searchOptions.bsnDateTo}`
            }
          ];

          const searchOptions = searchOptionsList
            .filter(item => !!item.value)
            .map(item => item.key + ': ' + item.value)
            .join(', ');

          const reportData = {
            reportJson: {
              jsonData: result,
              uniqueCode: this.$options.name,
              searchOptions,
            },
          };
          this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
        }
      },
      onExcelButtonClicked() {
        if (this.content.membershipChangeStatus.length === 0) {
          this.errorToast("조회된 데이터가 없습니다");
        } else {
          this.$refs.grid.excelExport({fileName:"회원권 변동 현황.xlsx"});
        }
      },
      membershipChangesStatusGridActionComplete() {
        this.count = numberWithCommas(
          this.$refs.grid?.getGridBatchCount() || 0
        );
      },

      onBodyGridQueryCellInfo(args) {
        const {
          cell,
              column: {
            field,
          },
          data,
        } = args;

        if(field === "renameDate" && data.isTopLine) {
          args.rowSpan = 2;
        }

        if (data[field] === 0) {
          cell.innerText = "-";
        }
        if (field === "renameDate") {
          cell.style.borderBottom = '0.1px solid #e0e0e0';

        }

        if (field === "renamDate") {
          if (data.isRowSpan) {
            args.rowSpan = data.rowSpan;
          }
        }
      },
    }
};
</script>
